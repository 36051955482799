// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {

  async getAsync(rawAsupSystemId, config) {
    const result = await axios.get(`/asup-management/rawasupsystem/${rawAsupSystemId}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getDataInAsync(rawAsupSystemId, datainId, config) {
    const result = await axios.get(`/asup-management/rawasupsystem/${rawAsupSystemId}/datains/${datainId}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getDataInListAsync(rawAsupSystemId, params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get(`/asup-management/rawasupsystem/${rawAsupSystemId}/datains`, requestConfig)
    return result.data
  },

  async downloadDataInAsync(rawAsupSystemId, id, preferedFileType, config) {
    const result = await axios.get(
      `/asup-management/rawasupsystem/${rawAsupSystemId}/datains/${id}/download?preferedFileType=${preferedFileType}`,
      buildAxiosRequestConfig(
        config,
        requestConfig => {
          // eslint-disable-next-line no-param-reassign
          requestConfig.responseType = 'arraybuffer'
        },
      ),
    )

    return result
  },

}
