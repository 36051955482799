<template>
  <div>
    <b-overlay :show="isLoading">
      <b-card title="Autosupport data">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Subject</th>
                <th title="Attention required">
                  Att.
                </th>
                <th>Size</th>
                <th style="width: 200px">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="data in rawAsupDataIn"
                :key="data.id"
              >
                <td>{{ data.dataTimestampUtc | formatDateTime }}</td>
                <td>{{ data.subject }}</td>
                <td>
                  <monitor-status-icon
                    :status="getStatusIconValue(data.attentionRequired)"
                  />
                </td>
                <td>{{ data.dataSize | prettyBytes }}</td>
                <td>
                  <template v-if="data.isDDRawData">
                    <b-button
                      v-b-tooltip.hover
                      size="sm"
                      title="Preview"
                      @click="preview(data.id)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      size="sm"
                      class="ml-1"
                      title="Download as .json"
                      @click="download(data.id, 1)"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                      v-b-tooltip.hover
                      size="sm"
                      title="Download as .eml"
                      @click="download(data.id, 0)"
                    >
                      <feather-icon icon="MailIcon" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      size="sm"
                      class="ml-1"
                      title="Download as .txt"
                      @click="download(data.id, 1)"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </b-button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-model="currentPage"
            class="mt-2"
            :total-rows="totalDataCount"
            align="right"
            :per-page="dataPerPage"
          />
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      ref="previewModal"
      size="xl"
      title="Preview"
      scrollable
    >
      <json-viewer
        v-if="dataInPreview.id"
        :value="dataInPreview.ddRawData"
        :expand-depth="1"
        copyable
        sort
      />

      <template #modal-footer="{ cancel }">
        <b-button
          variant="primary"
          @click="download(dataInPreview.id, 1)"
        >
          Download
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import JsonViewer from 'vue-json-viewer'
import {
  BCard, BPagination, BOverlay, BButton, VBTooltip, BModal,
} from 'bootstrap-vue'
import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'

import RawAsupSystemService from '@/service/rawAsupSystem.service'

export default {
  components: {
    BCard,
    BPagination,
    BOverlay,
    BButton,
    BModal,
    MonitorStatusIcon,
    JsonViewer,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rawAsupDataIn: [],
      totalDataCount: 0,
      dataPerPage: 10,
      currentPage: 1,
      isLoading: false,
      dataInPreview: {},
    }
  },
  computed: {
    requestSkipCount() {
      return this.dataPerPage * (this.currentPage - 1)
    },
  },
  watch: {
    currentPage() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    getStatusIconValue(attentionRequired) {
      return attentionRequired ? 30 : 0
    },
    download(id, preferedFileType) {
      RawAsupSystemService.downloadDataInAsync(this.asup.id, id, preferedFileType, { disableTenantFilter: true })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url

          if (result.headers['content-type'] === 'application/vnd.ms-outlook') {
            link.setAttribute('download', 'autosupport.eml')
          } else {
            link.setAttribute('download', 'autosupport.txt')
          }
          document.body.appendChild(link)
          link.click()
          link.remove()// you need to remove that elelment which is created before.
        })
    },
    preview(id) {
      this.isLoading = true
      RawAsupSystemService.getDataInAsync(this.asup.id, id, { disableTenantFilter: true })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.dataInPreview = result
          this.$refs.previewModal.show()
        })
        .catch(() => {
          this.$toast.error('Failed to retrieve data.', {
            icon: true,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    loadData() {
      this.isLoading = true
      RawAsupSystemService.getDataInListAsync(
        this.asup.id,
        {
          maxResultCount: this.dataPerPage,
          skipCount: this.requestSkipCount,
        },
        { disableTenantFilter: true },
      )
        .then(result => {
          this.rawAsupDataIn = result.items
          this.totalDataCount = result.totalCount
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
